@import '../../../../../styles/base/variables';
@import '../../../../../styles/vendor/icons';
@import '../../../../../styles/base/mixins';

.tile {
  border: 1px solid $gb_gdds_cl09;
  padding: 1.5rem;
  height: calc(100% - 1.5rem);
  position: relative;
  margin-bottom: 1.5rem;

  @include screen-md {
    &:hover {
      .overlay {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  :global(.c-tpp-area) {
    all: unset;
  }

  @include screen-lg {
    height: 9.5rem;
  }

  .tileContent {
    display: flex;
    flex-direction: row;
  }
}

.badges {
  position: absolute;
  top: -1rem;
  right: 1.5rem;
  z-index: 1;
  display: flex;
  flex-direction: row;

  > span {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: var(--gb-brand-primary-dark);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5rem;
  }
}

.image {
  width: 4.25rem;
  height: 6rem;
  object-fit: cover;
  margin-right: 1rem;
}

.overlay {
  @include screen-md {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $gb_gdds_cl03;
  }

  .overlayContent {
    display: grid;
    grid-template-columns: min-content min-content;
    column-gap: 2rem;
    row-gap: 1.5rem;
    color: black;
    margin-top: 1.5rem;

    @include screen-md {
      margin: 0 auto;
      max-width: 19rem;
      justify-content: center;
      align-items: center;
      column-gap: 2.5rem;
      row-gap: 1.5rem;
    }
  }

  a,
  button {
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    color: var(--gb-brand-primary);
    font-size: 0.875rem;
    white-space: nowrap;

    @include screen-md {
      color: $gb_black;
    }

    > span:first-child {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.5rem;

      @include screen-md {
        width: 2.5rem;
        height: 2.5rem;
        border: 1px solid $gb_black;
        border-radius: 50%;
      }
    }

    @include screen-md {
      &:hover,
      &.selected {
        > span:first-child {
          background-color: $gb_black;
          fill: $gb_white;
        }
      }
    }
  }
}

.information {
  display: flex;
  flex-direction: column;

  @media (min-width: $gb_breakpointMD) and (max-width: $gb_breakpointLG) {
    max-width: 68%;
  }

  .date {
    color: $gb_gdds_cl13;
    margin-bottom: 0.25rem;
  }

  .title {
    margin-bottom: 0.75rem;

    > span {
      width: 100%;
      line-clamp: 3;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;

      @include screen-lg {
        line-clamp: 2;
        -webkit-line-clamp: 2;
      }
    }

    &.titleKolo {
      > span {
        font-weight: $font-weight-medium;
      }
    }
  }

  .flags {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    @include screen-sm {
      flex-wrap: nowrap;
    }

    > span {
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
      white-space: nowrap;
      color: $gb_gdds_cl13;

      > span {
        margin-right: 0.5rem;
      }
    }

    @media (min-width: $gb_breakpointMD) and (max-width: $gb_breakpointXL) {
      .filename {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      > span:first-of-type {
        max-width: 50%;
        justify-content: start;
      }
    }
  }
}

.fileIcon {
  margin-right: 0.5rem;
}
