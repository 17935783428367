@import '../../../styles/base/variables';
@import '../../../styles/vendor/icons';
@import '../../../styles/base/mixins';

.listHead {
  margin-bottom: 1.5rem;
  line-height: 1rem;
  display: flex;
  justify-content: space-between;

  .courseCount {
    font-weight: $font-weight-bold;
  }

  .resultsPerPage {
    position: relative;

    strong {
      font-weight: $font-weight-bold;
    }

    > button::after {
      @include web20-icon(chevron-small-down);
      margin-left: 0.25rem;
    }

    &.open {
      > button::after {
        @include web20-icon(chevron-small-up);
      }
    }
  }

  .resultsPerPageDropdown {
    list-style-type: none;
    position: absolute;
    top: 1.625rem;
    right: 0;
    z-index: 60;
    background: $gb_white;
    box-shadow: $default-box-shadow;
    margin: 0;
    padding: 0;

    &::before {
      position: absolute;
      right: 0;
      transform: translateX(-50%);
      content: ' ';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0.625rem 0.625rem 0.625rem;
      border-color: transparent transparent $gb_white transparent;
      top: -0.625rem;
      z-index: 65;
    }

    &::after {
      position: absolute;
      right: -0.25rem;
      transform: translateX(-50%);
      content: ' ';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0.75rem 0.675rem 0.75rem;
      border-color: transparent transparent $gb_grey_100 transparent;
      top: -0.675rem;
    }

    li {
      list-style: none;
      display: block;
      margin: 0;
      padding: 0.25rem;

      button {
        white-space: nowrap;
        width: 100%;
        justify-content: flex-start;
        padding-left: 0.5rem;
      }
    }
  }
}

.listHeadCatalog {
  margin-bottom: 2.875rem;

  @include screen-sm {
    margin-bottom: 1.5rem;
  }
}

.divider {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.courseListBody {
  margin-bottom: 1.5rem;
  position: relative;
}

.loaderPlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.loaderLayer {
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}

.courseListFooter {
  text-align: center;
  margin-top: 1.5rem;

  .courseListPagination {
    margin: auto;
    display: inline-block;
    width: 100%;

    @include screen-sm {
      width: inherit;
    }

    :global(.flex-row) {
      display: flex;
      justify-content: space-between;
    }

    :global(.left),
    :global(.right) {
      padding: 0;

      @include screen-sm {
        padding: 10px 1.875rem;
      }
    }

    :global(.button i) {
      margin: 0 1rem;
      cursor: default;

      &::before {
        color: #bdbdbd;
        font-size: 1.05rem;
      }
    }

    :global(.button.is--active i::before) {
      color: $gb_black;
      cursor: pointer;
    }
  }
}

.course {
  background-color: $gb_grey_050;
  margin-top: 3rem;
  padding: 2rem 1.5rem 2rem 1.5rem;
  position: relative;

  @include screen-sm {
    flex-direction: row;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-top: 1.5rem;
  }

  :global(.data-wrapper) {
    display: flex;
    border-bottom: #d9d9d9 1px solid;
  }

  :global(.course-data) {
    flex-grow: 1;
    margin-top: 1.25rem;
    max-width: 47rem;

    @include screen-sm {
      margin-top: 0;
      width: auto;
    }

    :global(.provider) {
      background-color: var(--gb-brand-primary-dark);
      color: $gb_white;
      display: inline-block;
      font-size: 0.725rem;
      font-weight: 300;
      padding: 0.1875rem 0.625rem;
      margin-bottom: 0.875rem;
      letter-spacing: 0.01rem;
    }

    :global(.type) {
      font-size: 0.75rem;
      margin-bottom: 0.25rem;
    }

    :global(.text) {
      color: #6f6f6f;
      margin-bottom: 1rem;
    }
  }

  :global(.course-icons) {
    height: 3rem;
    position: absolute;
    top: -1.5rem;
    width: 3rem;

    @include screen-sm {
      left: -1.5rem;
      top: 1.25rem;
    }

    &:global(.webinar) {
      background-color: $gb_bgcolor_webinar;
    }

    &:global(.elearning) {
      background-color: $gb_bgcolor_elearning;
    }

    &:global(.webcast) {
      background-color: $gb_bgcolor_webcast;
    }

    &:global(.event) {
      background-color: $gb_bgcolor_event;
    }

    &:global(.seminar) {
      background-color: $gb_bgcolor_seminar;
    }

    :global(span) {
      display: flex;
      justify-content: center;
      position: relative;
    }

    :global(i) {
      font-size: 1.5rem;
      line-height: 1.8;
    }
  }

  :global(.actions) {
    position: relative;
    display: flex;
    margin-top: 1.5rem;
    width: 100%;

    @include screen-sm {
      float: right;
    }

    :global(button:last-child) {
      margin-right: 0;
    }

    @include screen-sm {
      margin-top: 0;
      width: auto;
    }

    :global(.more-wrapper) {
      button {
        margin-right: 0;
      }
    }

    :global(button) {
      margin-right: 1rem;
      flex-grow: 1;

      &:global(.open) {
        background-color: $gb_black;
        color: $gb_white;
      }

      &:global(.more) {
        padding: 0.625rem;
        font-size: 1.2rem;
        line-height: 1rem;
        flex-grow: unset;
      }
    }

    :global(.more-menu) {
      position: absolute;
      top: 0;
      background-color: $gb_white;
      box-shadow: 0px 2px 6px 0px $gb_grey_400;

      :global(a),
      :global(button) {
        display: block;
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
        font-weight: 500;
        white-space: nowrap;
      }
    }
  }

  :global(.course-information-items) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.courseTitle {
  text-transform: unset;
  font-weight: 600;
  font-size: 1.4em;
  margin: 0 0 1rem 0;
  max-width: 44rem;
  padding-right: 1rem;

  @include line-clamp(3);

  @include screen-sm {
    @include line-clamp(2);
  }

  @include screen-md {
    @include line-clamp(1);
  }
}

a.certificateDisabled {
  pointer-events: none;
  /* somehow browser does not use this for all if not important */
  color: $gb_grey_150 !important;
}
